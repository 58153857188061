import React from 'react';
import carouselImage1 from '../assets/images/carouselImage1.jpg'; 
import carouselImage2 from '../assets/images/carouselImage2.jpg'; 
import carouselImage3 from '../assets/images/carouselImage3.jpg'; 

import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Home.css';

const Home = () => {
  return (
    <div>
      <div className="row">
        <Carousel interval={2000} >
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src={carouselImage2}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src={carouselImage3}
              alt="Second slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default Home;
